import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { UrlsService } from './urls.service';

@Injectable({ providedIn: 'root' })
export class RouterService {
  readonly _router = inject(Router);
  readonly _urls = inject(UrlsService);

  addParam(filter: string, value: string) {
    this._router.navigate([], {
      queryParams: { [filter]: value },
      queryParamsHandling: 'merge'
    });
  }

  addFilter(filter: string, value: string) {
    this._router.navigate([], {
      queryParams: { [filter]: value, page: 1 },
      queryParamsHandling: 'merge'
    });
  }

  navigateToLogin() {
    this._router.navigateByUrl(this._urls.LOGIN_URL);
  }

  navigateToSignup() {
    this._router.navigateByUrl(this._urls.SIGNUP_URL);
  }

  navigateToForgotPassword() {
    this._router.navigateByUrl(this._urls.FORGOT_PASSWORD_URL);
  }

  navigateToCorpora() {
    this._router.navigateByUrl(this._urls.CORPORA_URL);
  }

  navigateToHome() {
    this._router.navigateByUrl(this._urls.APP_URL);
  }

  navigateToPMNotFound() {
    this._router.navigateByUrl(this._urls.PM_NOT_FOUND);
  }

  navigateToLabelSets() {
    this._router.navigateByUrl(this._urls.LABEL_SETS_URL);
  }

  navigateToPublicCorpora() {
    this._router.navigateByUrl(this._urls.PUBLIC_CORPORA_URL);
  }

  navigateToPublicLabelSets() {
    this._router.navigateByUrl(this._urls.PUBLIC_LABEL_SETS_URL);
  }

  navigateToSpanLabeleds() {
    this._router.navigateByUrl(this._urls.SPAN_LABELED_URL);
  }

  navigateToTutorials() {
    this._router.navigateByUrl(this._urls.TUTORIALS_URLS);
  }

  navigateToTutorial(tutorialId: string) {
    this._router.navigateByUrl(this._urls.getTutorialUrl(tutorialId));
  }

  navigateToUserSettings() {
    this._router.navigateByUrl(this._urls.USER_SETTINGS_URL);
  }

  navigateToSearch() {
    this._router.navigateByUrl(this._urls.SEARCH_URL);
  }

  navigateToSearchQ(q: string) {
    this._router.navigate([this._urls.SEARCH_URL], { queryParams: { q } });
  }

  navigateToClassificationItem(corpusId: number, textId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationAnnotationUrl(corpusId, textId)
    );
  }

  navigateToCorpus(corpusId: number) {
    this._router.navigateByUrl(this._urls.getCorpusUrl(corpusId));
  }

  navigateToLabelSet(labelSetId: number) {
    this._router.navigateByUrl(this._urls.getLabelSetUrl(labelSetId));
  }

  navigateToLandingPage() {
    this._router.navigateByUrl(this._urls.LANDING_PAGE_URL);
  }

  navigateToSpanLabeled(corpusId: number) {
    this._router.navigateByUrl(this._urls.getSpanLabeledUrl(corpusId));
  }

  navigateToSpanLabeledLabels(corpusId: number) {
    this._router.navigateByUrl(this._urls.getSpanLabeledLabelsUrl(corpusId));
  }

  navigateToSpanLabeledStatistics(corpusId: number) {
    this._router.navigateByUrl(
      this._urls.getSpanLabeledStatisticsUrl(corpusId)
    );
  }

  navigateToSpanLabeledTexts(corpusId: number) {
    this._router.navigateByUrl(this._urls.getSpanLabeledTextsUrl(corpusId));
  }

  navigateToSpanSpanLabeledCorpusItem(corpusId: number, textId: number) {
    this._router.navigateByUrl(
      this._urls.getSpanLabeledAnnotationTextUrl(corpusId, textId)
    );
  }

  navigateToTextClassification(corpusId: number) {
    this._router.navigateByUrl(this._urls.getTextClassificationUrl(corpusId));
  }

  navigateToTextClassificationLabels(corpusId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationLabelsUrl(corpusId)
    );
  }

  navigateToTextClassificationStatistics(corpusId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationStatisticsUrl(corpusId)
    );
  }

  navigateToTextClassificationTexts(corpusId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationTextsUrl(corpusId)
    );
  }

  navigateToTextClassificationAnnotationUrl(corpusId: number, textId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationAnnotationUrl(corpusId, textId)
    );
  }

  navigateToSpanTextClassificationCorpusItem(corpusId: number, textId: number) {
    this._router.navigateByUrl(
      this._urls.getTextClassificationAnnotationUrl(corpusId, textId)
    );
  }

  navigateToUser(username: string) {
    this._router.navigateByUrl(this._urls.getUserUrl(username));
  }

  removeFilter(filter: string) {
    this._router.navigate([], {
      queryParams: { [filter]: null },
      queryParamsHandling: 'merge'
    });
  }
}
